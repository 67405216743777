(function ($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};

  Drupal.behaviors.ELB_welcome15 = {
    debug: false, // when debugging, the overlay opens on all page loads
    showThanks: true, // even if there's an error, show the "thank you" message
    showSignedUp: false, // even if there's an error, show the "already signed up" message

    // storage cookie name
    offerCookie: 'welcome15',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,
    // template path config
    templates: {
      form: 'homepage_popup_kr_v1'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'welcome15-overlay',
        transition: 'none',
        height: '280px',
        width: '361px'
      },
      mobile: {
        className: 'welcome15-overlay',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        opacity: '0',
        transition: 'none'
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent: function (key, data) {
      var undef;

      // Sanitize the incoming data
      path = key !== undef ? key : 'foobar_template';
      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length == 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data == false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, rb, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      // Add welcome15 body CSS class
      $('body').addClass('welcome15');

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
        html: content,
        height: '352px',
        maxWidth: '100%',
        maxHeight: '100%',
        width: '461px'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      darg.height = rb.homepage_popup_height;
      darg.width = rb.homepage_popup_width;
      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function (rb, popup_timeout) {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (useAltMarkUp) {
          cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcome15-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, rb, {
          // Hide the content and overlay in onOpen, so we can fade it in 10/16/2017
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcome15.initSignUpForm();
            $('.terms-conditions-link').on('click', function (e) {
              e.preventDefault();
              $('.site-email-signup__terms-details').show();
              $('.site-email-signup__terms-details').animate({
                height: '100%',
                width: '100%',
                overflow: 'auto'
              });
            });

            $('.site-email-signup__close-terms').on('click', function (e) {
              e.preventDefault();
              $('.site-email-signup__terms-details').animate(
                {
                  height: 0,
                  width: 0,
                  overflow: 'hidden'
                },
                function () {
                  $('.site-email-signup__terms-details').hide();
                }
              );
            });
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }
          }
        });
      }, popup_timeout);
    },

    /**
     * Helper function to determine if the welcome15 offer should appear on this page
     * @TODO: this will likely be expanded to be disabled on checkout and whatnot
     */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false,
        undef;
      // adding to allow manual disabling of the popover
      var disabled = 0;

      if (hasCookie == undef) {
        // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            expires: 1,
            path: '/'
          });

          return false;
        }
      }

      if (self.debug || hasCookie == undef) {
        showOffer = true;
      }

      return showOffer;
    },

    setCookie: function () {
      var expDate = new Date();

      expDate.setDate(expDate.getDate() + 30);
      var expDateString = expDate.toGMTString();

      cookieVal = $.cookie('welcome15');
      if (cookieVal === null || cookieVal === undefined) {
        generic.cookie('welcome15', '1', {
          path: '/',
          expires: expDateString
        });
        cookieVal = 1;
      } else if (cookieVal !== null) {
        cookieVal = parseInt(cookieVal);
        cookieVal++;
        generic.cookie('welcome15', cookieVal, {
          path: '/',
          expires: expDateString
        });
      }
    },

    attach: function (context, settings) {
      var self = this,
        cookieName = self.offerCookie;

      if (rb.language.enable_popup) {
        this.setCookie();
        if (rb.language.popup_show_count >= cookieVal) {
          this.showSignupForm(rb.language);
        }
      }
    }
  };

  site.offers.welcome15 = {
    behavior: Drupal.behaviors.ELB_welcome15,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    initSignUpForm: function () {
      var self = site.offers.welcome15,
        overlay = self.getOverlayContent();

      $('.welcome15-overlay #cboxContent,#cboxOverlay').fadeOut(1, function () {
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            $('.welcome15-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
          });
      });
    }
  };
})(jQuery);
